<template>
  <div class="label row g-8" :class="{energyEfficiency, rightLabel}" :style="{background: background, color:color}">
    <img v-if="img" :src="img"/>
    <component width="14px" height="16px" :stroke="color" :is="icon" v-if="icon"/>
    {{ name }}
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    background: String,
    color: String,
    img: String,
    icon: Object,
    energyEfficiency: {},
    rightLabel: false,
    absolute: {
      type: Boolean,
      default: true
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";

.label {
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: .44px;
  text-transform: uppercase;
  min-width: 95px;
  padding: 2px 16px 0;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @include smd {
    font-size: 9px;
    min-width: 61px;
    height: 21px;
    max-height: 21px;
    min-height: unset;
    padding: 0 8px;
    letter-spacing: 0.36px;
  }

  &.energyEfficiency {
    min-width: 55px;
    width: 55px;
    justify-content: end;
    font-size: 16px;

    &::before {
      content: '';
      width: 20px;
      margin-right: -.5px;
      clip-path: polygon(49% 53%, 100% 100%, 100% 0);
      top: 0;
      position: absolute;
      bottom: 0;
      left: -19.5px;
      background: inherit;
    }

    &.rightLabel {
      &::before {
        left: unset;
        right: -19px;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
